import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../common/header/Header";
import Button from "../../common/button/Button";
import loginHeader from "../../assets/loginHeader.png";
import { sendMsg } from "../../../messenger/messenger";

const Login = ({ state, dispatch, messenger }) => {
  const formRef = useRef(null);
  const [teamName, setTeamName] = useState(state.teamName);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const isFormValid = formRef.current.checkValidity();

    if (isFormValid) {
      console.log("Form is valid!");
      console.log("teamName: ", teamName);
      dispatch({
        type: "changeLocalTeamName",
        oldName: state.teamName,
        newName: teamName,
      });
      sendMsg(messenger, {
        type: "changeTeamName",
        oldName: state.teamName,
        newName: teamName,
      });
      navigate(`/questions`);
      formRef.current.reset();
    } else {
      console.log("Form is not valid");
    }
  };

  return (
    <div className="h-screen flex flex-col flex-1 px-2 pb-2 lg:px-8">
      <div className="h-full flex flex-col justify-end sm:mx-auto sm:w-full sm:max-w-sm md:max-w-2xl">
        <Header src={loginHeader} width="md:max-w-sm" />
        <div className="h-auto flex flex-col justify-between bg-grey-main rounded-4xl pt-6 pb-3 px-4 sm:mx-auto sm:w-full sm:max-w-sm md:max-w-2xl">
          <div className="pb-16">
            <h1 className="fontBold text-3xl text-center pb-6">Login</h1>
            <p className="fontBold text-grey-primary text-base text-center">
              Login to play the Innovation & Curiosity 30 Second Game
            </p>
          </div>
          <form method="POST" ref={formRef} onSubmit={handleSubmit}>
            <div className="mb-24">
              <label htmlFor="fullName" className="fontBold block text-base">
                Full Name
              </label>
              <div className="mt-2">
                <input
                  id="fullName"
                  name="fullName"
                  type="text"
                  defaultValue={teamName}
                  onChange={(e) => setTeamName(e.target.value)}
                  required
                  autoComplete="text"
                  placeholder="Full Name"
                  className="fontRegular block w-full rounded-1xl px-2 py-3 text-base text-black  placeholder:text-grey-primary placeholder:text-base focus:outline-none"
                />
              </div>
            </div>
            <Button
              classNames={
                "fontBold flex w-64 mx-auto justify-center rounded-1xl bg-green-main text-white-main text-xl py-3"
              }
              onClick={handleSubmit}
            >
              Login
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
