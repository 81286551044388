import React from "react";
import { sendMsg } from "../../../messenger/messenger";
import { teamCorrectAnswers, teamTotalTime } from "../../../state/appstate";
import Timer from "../../common/timer/Timer";
import Table from "../../common/table/Table";
import Chart from "../../common/chart/Chart";

const Dashboard = ({ state, dispatch, messenger }) => {
  document.body.style.setProperty("background", "#E4E4E4");

  function getOverviewData() {
    let result = Array.from(state.teams.values()).map((teamData) => {
      let answered = teamData.answers.size;
      let correct = teamCorrectAnswers(teamData, state.questionsAndAnswersData);
      let time = Number(teamTotalTime(teamData)).toFixed(2);
      return {
        name: teamData.name,
        answered,
        correct,
        time,
      };
    });
    return result;
  }

  const overviewData = getOverviewData()
    .sort((a, b) => b.time - a.time)
    .sort((a, b) => b.correct - a.correct);
  const overviewColumns = [
    { header: "Name", accessor: "name", width: "w-4/12" },
    { header: "Answered", accessor: "answered", center: true },
    { header: "Correct Answers", accessor: "correct", center: true },
    { header: "Total Time", accessor: "time", center: true },
  ];

  const accurateRespondersData = overviewData
    .map((data) => {
      return { name: data.name, correct: data.correct };
    })
    .sort((a, b) => b.correct - a.correct) // sort by correct answers
    .slice(0, 5); // only take the top 5
  const accurateRespondersColumns = [
    { header: "Name", accessor: "name", width: "w-9/12" },
    { header: "Correct", accessor: "correct", center: true },
  ];

  const quickestRespondersData = overviewData
    .map((data) => {
      return { name: data.name, correct: data.correct, time: Number(data.time).toFixed(2) };
    })
    .sort((a, b) => a.time - b.time) // first sort by time taken
    .slice(0, 5); // only take the top 5
  // .sort((a,b) => b.correct-a.correct); // finally sort by correct answers
  const quickestRespondersColumns = [
    { header: "Name", accessor: "name", width: "w-9/12" },
    { header: "Time", accessor: "time", center: true },
    // { header: "Correct", accessor: "correct", center: true },
  ];

  function calcAnswerSeries() {
    let totalAnswers = [0,0,0,0,0,0,0,0];
    let correctAnswers = [0,0,0,0,0,0,0,0];
    state.teams.forEach(teamData => {
      teamData.answers.forEach((answerData, index) => {
        if (index >= 0 && index < state.questionsAndAnswersData.length) {
          totalAnswers[index] = totalAnswers[index] + 1;
          let correctAnswerNo = state.questionsAndAnswersData[index].answer;
          if (answerData.answer === correctAnswerNo) {
            correctAnswers[index] = correctAnswers[index] + 1;
          }
        }
      })
    })
    let result = [
      {
      name: "Total Answers",
      data: totalAnswers
      },
      {
      name: "Correct Answers",
      data: correctAnswers
      },
    ];
    return result;
  }

  return (
    <div className="grid max-h-screen grid-cols-8 pb-9 relative">
      <div className="fontRegular col-span-7 content-center text-xl border-b border-grey-tertiary px-5 py-3">
        <div className="flex w-full gap-4 items-center">
          <div class="w-7 h-7 bg-green-tertiary rounded-full"></div>
          <h2>
            Welcome to the Innovation & Curiosity 30-Second Game Dashboard
          </h2>
        </div>
      </div>
      {state.currentQuestionIndex > -1 && (
        <div className="row-span-2">
          <Timer size={110} dashboard={true} key={state.currentQuestionIndex} />
        </div>
      )}
      <div className="flex flex-col items-end col-span-7">
        <div className="flex justify-end gap-2 pt-1">
          {state.currentQuestionIndex > 0 && (
            <button
              className="flex gap-4 fontBold bg-yellow-primary py-1 px-4 rounded-md uppercase text-sm text-white-main items-center shadow-[1px_5px_25px_-8px_rgba(124,124,124,0.46)]"
              onClick={() =>
                sendMsg(messenger, {
                  type: "showQuestion",
                  questionIndex: state.currentQuestionIndex - 1,
                })
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={0.5}
                stroke="white"
                className="size-10"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 16.811c0 .864-.933 1.406-1.683.977l-7.108-4.061a1.125 1.125 0 0 1 0-1.954l7.108-4.061A1.125 1.125 0 0 1 21 8.689v8.122ZM11.25 16.811c0 .864-.933 1.406-1.683.977l-7.108-4.061a1.125 1.125 0 0 1 0-1.954l7.108-4.061a1.125 1.125 0 0 1 1.683.977v8.122Z"
                />
              </svg>
              Prev Question
            </button>
          )}
          {state.currentQuestionIndex <
            state.questionsAndAnswersData.length - 1 && (
            <button
              className="flex gap-4 fontBold bg-yellow-primary py-1 px-4 rounded-md uppercase text-sm text-white-main items-center shadow-[1px_5px_25px_-8px_rgba(124,124,124,0.46)]"
              onClick={() =>
                sendMsg(messenger, {
                  type: "showQuestion",
                  questionIndex: state.currentQuestionIndex + 1,
                })
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={0.5}
                stroke="white"
                className="size-10"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061A1.125 1.125 0 0 1 3 16.811V8.69ZM12.75 8.689c0-.864.933-1.406 1.683-.977l7.108 4.061a1.125 1.125 0 0 1 0 1.954l-7.108 4.061a1.125 1.125 0 0 1-1.683-.977V8.69Z"
                />
              </svg>
              Next Question
            </button>
          )}
        </div>
        <p className="fontLight text-sm text-yellow-primary pt-1">
          {`Current Question ${state.currentQuestionIndex + 1}`}
        </p>
      </div>
      <p className="col-span-8 text-lg ps-8 pb-2">Total Top 5's</p>
      <div className="grid grid-cols-subgrid h-max col-span-4 ps-5 gap-x-3 gap-y-3">
        <Table
          tableData={quickestRespondersData}
          columns={quickestRespondersColumns}
          title="quickest accurate responders"
          bg="bg-gradient-to-r from-[#83C482] from-0% to-[#49B897] to-100%"
          shadow="shadow-[1px_17px_70px_3px_rgba(73,184,151,0.48)]"
          colspan="col-span-2"
          color="text-white-main"
          padding="py-3"
        />
        <Table
          tableData={accurateRespondersData}
          columns={accurateRespondersColumns}
          title="accurate responders"
          bg="bg-green-primary"
          shadow="shadow-[1px_17px_70px_3px_rgba(131,196,130,0.46)]"
          colspan="col-span-2"
          color="text-white-main"
          padding="py-3"
        />
        <div className="col-span-4 bg-white-primary rounded-1xl pt-3 ps-1 pe-3 shadow-[1px_17px_70px_3px_rgba(177,177,177,0.46)]">
          <Chart answerData={calcAnswerSeries()}/>
        </div>
      </div>
      <div className="grid grid-cols-subgrid col-span-4 h-max px-4">
        <p className="col-span-4 text-lg ps-8 pb-2">Quick Overview</p>
        <div className="flex col-span-4 rounded-1xl mx-3 mb-4 py-2 border border-grey-tertiary justify-evenly">
          <div className="flex items-center gap-10 border-e border-grey-tertiary w-full justify-around">
            <div className="flex flex-col">
              <p className="fontBold text-2xl">{state.teams.size}</p>
              <p className="fontBold uppercase text-xs text-grey-primary">
                Participants
              </p>
            </div>
            <div className="rounded-1xl bg-green-quarternary px-6 py-1 shadow-[1px_17px_70px_3px_rgba(73,184,151,0.48)] z-10">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={0.5}
                stroke="white"
                className="size-10"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                />
              </svg>
            </div>
          </div>
          <div className="flex items-center gap-10 w-full justify-around">
            <div className="flex flex-col">
              <p className="fontBold text-2xl">19</p>
              <p className="fontBold uppercase text-xs text-grey-primary">
                Pass Count
              </p>
            </div>
            <div className="rounded-1xl bg-green-primary quarternary px-6 py-1 shadow-[1px_17px_70px_3px_rgba(131,196,130,0.46)] z-10">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={0.5}
                stroke="white"
                className="size-10"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="col-span-4 h-[25rem] overflow-y-scroll">
          <Table
            tableData={overviewData}
            columns={overviewColumns}
            colspan="col-span-4"
            rowspan="row-span-2"
            colorThead="text-grey-primary border-grey-primary"
            borderColor="border-grey-tertiary"
            bgHeadColor="bg-grey-secondary"
          />
        </div>
      </div>
      <svg
        // width="1540"
        height="573"
        viewBox="0 0 1540 573"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute -z-10 left-0 bottom-0 w-full"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2151.42 0L2092.5 18.8517C2031.74 38.0261 1913.88 75.7295 1794.2 113.756C1674.52 151.782 1556.66 189.485 1431.19 194.716C1305.72 199.948 1176.29 172.062 1056.61 210.088C936.921 248.114 815.602 341.382 703.151 420.402C590.7 499.421 490.774 563.547 366.75 576.977C242.726 590.407 113.298 562.521 46.7539 548.9L-17.9603 534.957L16.7594 731.726L77.1336 721.073C139.337 710.097 260.085 688.791 382.663 667.162C505.241 645.533 625.989 624.228 748.567 602.599C871.145 580.97 991.893 559.664 1114.47 538.035C1237.05 516.406 1357.8 495.1 1480.38 473.472C1602.95 451.843 1723.7 430.537 1846.28 408.908C1968.86 387.279 2089.61 365.973 2151.81 354.998L2212.18 344.345L2151.42 0Z"
          fill="#49B897"
          fill-opacity="0.07"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1888.61 151L1844.18 166.238C1798.38 181.79 1709.54 212.266 1619.31 243.056C1529.09 273.847 1440.24 304.322 1345.59 315.707C1250.94 327.092 1153.24 318.757 1063.02 349.547C972.792 380.338 881.438 444.562 796.747 499.609C712.056 554.656 636.787 599.896 543.243 616.132C449.698 632.369 351.999 624.034 301.77 620.181L252.921 616.013L279.482 732.445L325.012 722.059C371.92 711.358 462.979 690.585 555.417 669.497C647.855 648.409 738.913 627.637 831.351 606.549C923.789 585.461 1014.85 564.688 1107.29 543.601C1199.72 522.513 1290.78 501.74 1383.22 480.653C1475.66 459.565 1566.72 438.792 1659.15 417.704C1751.59 396.617 1842.65 375.844 1889.56 365.143L1935.09 354.756L1888.61 151Z"
          fill="#49B897"
          fill-opacity="0.08"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1957.61 274L1913.27 289.632C1867.56 305.579 1778.9 336.843 1688.85 368.421C1598.81 400 1510.14 431.264 1415.31 441.86C1320.48 452.457 1222.24 441.757 1132.2 473.336C1042.15 504.914 951.562 572.489 867.5 630.296C783.439 688.102 708.665 735.509 615.03 751.351C521.396 767.194 423.157 756.494 372.659 751.458L323.54 746.108L352.259 872L397.788 861.614C444.697 850.912 535.755 830.139 628.193 809.052C720.631 787.964 811.69 767.191 904.127 746.104C996.565 725.016 1087.62 704.243 1180.06 683.155C1272.5 662.068 1363.56 641.295 1456 620.207C1548.43 599.12 1639.49 578.347 1731.93 557.259C1824.37 536.171 1915.43 515.398 1962.34 504.697L2007.86 494.311L1957.61 274Z"
          fill="#9CBEB4"
          fill-opacity="0.79"
        />
      </svg>
    </div>
  );
};

export default Dashboard;
