import React from "react";
import ChartApex from "react-apexcharts";

const Chart = ({answerData}) => {
  const chartOptions = {
    chart: {
      id: "lineGraph",
      redrawOnParentResize: true,
      // redrawOnParentResize: true,
      // height: 100,
    },
    colors: ["#c1bb38","#49B897"],
    stroke: {
      curve: "smooth",
      width: 2,
    },
    markers: {
      size: 4,
      strokeWidth: 0,
    },
    xaxis: {
      categories: ["Q1", "Q2", "Q3", "Q4", "Q5", "Q6", "Q7", "Q8"],
      labels: {
        style: {
          fontSize: "12px",
          colors: ["#7F7F7F"],
          fontFamily: "Poppins",
          fontWeight: "normal",
        },
      },
      title: {
        text: "NO. OF CORRECT ANSWERS",
        style: {
          fontSize: "12px",
          colors: ["#7F7F7F"],
          fontFamily: "Poppins",
          fontWeight: "normal",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: "12px",
          colors: ["#7F7F7F"],
          fontFamily: "Poppins",
          fontWeight: "normal",
        },
      },
    },
    title: {
      text: "CORRECT ANSWERS PER QUESTION",
      align: "left",
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: "Poppins",
        color: "#000000",
      },
    },
    grid: {
      borderColor: "#7F7F7F",
      strokeDashArray: 0.2,
    },
    tooltip: {
      style: {
        fontFamily: "Poppins",
      },
    },
  };

  // const [series, setSeries] = useState([
  //   {
  //     name: "Correct Answers",
  //     data: [0, 7, 8, 6, 3, 2, 0, 1],
  //   },
  // ]);

  return (
    <>
      <ChartApex
        options={chartOptions}
        series={answerData}
        type="line"
        // width="715"
        height="268"
      />
    </>
  );
};

export default Chart;
