import React, { useReducer, useState, useEffect } from 'react';
import { createBrowserRouter, RouterProvider} from 'react-router-dom';
import Login from './components/pages/login/Login';
import IntroQuestions from './components/pages/introquestions/IntroQuestions';
import Summary from './components/pages/summary/Summary';
import Dashboard from './components/pages/dashboard/Dashboard';
import { appStateReducer, initialAppState } from './state/appstate';
import { initMessenger } from './messenger/messenger';

function App() {
  const [appState, dispatch] = useReducer(appStateReducer, initialAppState);
  const [messenger, setMessenger] = useState(undefined);
  const [messengerCreated, setMessengerCreated] = useState(false);


  useEffect(() => {
    setMessengerCreated(oldMessengerCreated => {
      if (!oldMessengerCreated) {
        // makes sure messenger is only created once
        const newMessenger = initMessenger(dispatch);
        setMessenger(newMessenger);
      }
      return true;
    })
  },[]);

  const router = createBrowserRouter([
    {
      path: `/`,
      element: <Login state={appState} dispatch={dispatch} messenger={messenger}/>,
      errorElement: <div>404 Not Found</div>
    },
    {
      path: `/login`,
      element: <Login state={appState} dispatch={dispatch} messenger={messenger}/>,
    },
    {
      path: `/questions`,
      element: <IntroQuestions state={appState} dispatch={dispatch} messenger={messenger}/>
    },
    {
      path: `/summary`,
      element: <Summary state={appState} dispatch={dispatch} messenger={messenger}/>
    },
    {
      path: `/dashboard`,
      element: <Dashboard state={appState} dispatch={dispatch} messenger={messenger}/>
    }
  ]);

  return (
    <RouterProvider router={router} />
  );
}

export default App;