import React from "react";

const Table = ({
  tableData,
  columns,
  title,
  bg,
  shadow,
  colspan,
  rowspan,
  color,
  colorThead,
  borderColor,
  padding,
  bgHeadColor,
}) => {
  return (
    <>
      <div
        className={`${colspan} ${rowspan} rounded-1xl px-4 ${padding} ${bg} ${shadow}`}
      >
        {title && (
          <p className="fontBold uppercase text-sm text-white-main pb-3">
            {title}
          </p>
        )}
        <table
          className={`table-auto relative w-full border-spacing-3 ${color}`}
        >
          <thead
            className={`fontBold uppercase text-sm border-b ${borderColor} ${colorThead}`}
          >
            <tr>
              {columns.map((column, index) => (
                <td
                  key={index}
                  className={`py-1 sticky top-0 ${bgHeadColor} ${
                    column.center ? "text-center" : ""
                  } ${column.width}`}
                >
                  {column.header}
                </td>
              ))}
            </tr>
          </thead>
          <tbody className="fontLight text-xs">
            {tableData.map((row, rowIndex) => (
              <tr key={rowIndex} className={`border-b ${borderColor}`}>
                {columns.map((column, colIndex) => (
                  <td
                    key={colIndex}
                    className={`py-1.5 ${column.center ? "text-center" : ""}`}
                  >
                    {row[column.accessor]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Table;
