import mqtt from "mqtt";

// export const mqttServer = "wss://mh.crgsa.co.za:8083/";
export const mqttServer = "wss://test.mosquitto.org:8081/";
export const messengerTopic = "SUPERCARE_QUIZ";
// export const messengerTopic = "SUPERCARE_QUIZ_TEST";

export function initMessenger(dispatch) {
  console.log("Initializing new messenger. Connecting to "+mqttServer);
  let newMessenger = mqtt.connect(mqttServer);
  console.log("Messenger: ", newMessenger);
  newMessenger.subscribe(messengerTopic);
  newMessenger.on("message", function (msgTopic, message) {
    let messageData = JSON.parse(message.toString());
    console.log("Received messageData: ", messageData);
    switch (messageData.type) {
      case "changeTeamName": {
        console.log(
          "New Team Name message received. Old Name: " +
            messageData.oldName +
            " New Name: " +
            messageData.newName
        );
        dispatch(messageData);
        break;
      }
      case "showQuestion": {
        console.log(
          "Show Question message received. Question Index: " +
            messageData.questionIndex
        );
        dispatch(messageData);
        break;
      }
      case "submitAnswer": {
        console.log("submitAnswer message received.")
        dispatch(messageData);
        break;
      }
      default: {
        console.warn("Unrecognised Message Action");
      }
    }
  });
  newMessenger.on("offline", () => {
          console.log("Messenger is offline");
        });
  return newMessenger;
}

export function sendMsg(messenger, message) {
  if (messenger) {
    console.log(
      "Publisher message sent: Message: ",
      message
    );
    messenger.publish(messengerTopic, JSON.stringify(message));
  }
}