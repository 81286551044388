import { questionsAndAnswersData } from "./questions-answers";

export const initialAppState = {
  questionsAndAnswersData: questionsAndAnswersData,
  currentQuestionIndex: -1,
  currentQuestion: questionsAndAnswersData[0],
  currentQuestionAnswer: "",
  questionStartTime: 0,
  teamName: "",
  teams: new Map(),
  
}

export function appStateReducer(oldState, action) {
  let newState = {...oldState};
  newState.teams = oldState.teams; // oldState not deep copied
  switch (action.type) {
    case "changeLocalTeamName": {
      console.log("Updating local team name from "+ action.oldName +" to "+action.newName);
      newState.teamName = action.newName;
      break;
    }
    case "changeTeamName": {
      console.log("Updating team name from "+ action.oldName +" to "+action.newName);
      let newTeamData = undefined;
      if (action.oldName) {
        newTeamData = oldState.teams.get(action.oldName);
        newState.teams.delete(action.oldName);
      }
      if (!newTeamData && action.newName) {
        newTeamData = createNewTeamData(action.newName);
      } else {
        newTeamData.name = action.newName;
      }
      newState.teams.set(newTeamData.name, newTeamData);
      console.log("new teams: ", newState.teams);
      break;
    }
    case "showQuestion": {
      if (newState.questionsAndAnswersData.length > 0) {
        if (action.questionIndex >= newState.questionsAndAnswersData.length) {
          action.questionIndex = newState.questionsAndAnswersData.length-1;
        }
        if (action.questionIndex < 0) {
          action.questionIndex = 0;
        }
        console.log("Showing question with index: "+action.questionIndex);
        newState.currentQuestionIndex = action.questionIndex;
        newState.currentQuestion = newState.questionsAndAnswersData[action.questionIndex];
        newState.currentQuestionAnswer = "";
        if (newState.teamName && newState.teams.has(newState.teamName)) {
          let teamData = newState.teams.get(newState.teamName);
          let existingQuestionAnswer = teamData.answers.get(newState.currentQuestionIndex);
          if (existingQuestionAnswer) {
            newState.currentQuestionAnswer = existingQuestionAnswer.answer;
          }
        }
        newState.questionStartTime = new Date().getTime();
      }
      break;
    }
    case "submitLocalAnswer": {
      newState.currentQuestionAnswer = action.answer;
      break;
    }
    case "submitAnswer": {
      let teamData = newState.teams.get(action.team);
      if (!teamData) {
        teamData = createNewTeamData(action.team);
      }
      let existingAnswer = teamData.answers.get(action.questionIndex);
      if (!existingAnswer || existingAnswer.answer !== action.answer ) {
        // only update answer if it is different to existing answer
        teamData.answers.set(action.questionIndex, {answer: action.answer, time: action.time});
        newState.teams.set(action.team, teamData);
      }
      break;
    }
    case "disableAnswers": {
      newState.questionStartTime = 0;
      break;
    }
    default:
  }
  return newState;
}

function createNewTeamData(name) {
  return {
    name,
    answers: new Map(),
  }
}

export function teamCorrectAnswers(teamData, questionsAndAnswersData) {
  let correctAnswers = 0;
  teamData.answers.forEach((value, index) => {
    if (index >= 0 && index < questionsAndAnswersData.length) {
      let correctAnswerNo = questionsAndAnswersData[index].answer;
      if (value.answer === correctAnswerNo) {
        correctAnswers = correctAnswers + 1;
      }
    }
  })
  return correctAnswers;
}

export function teamTotalTime(teamData) {
  let totalTime = Array.from(teamData.answers.values()).reduce((total, value) => total+value.time,0);
  console.log("Total time:", totalTime, teamData);
  return totalTime;
}

