export const questionsAndAnswersData = [
  {
    part: "1",
    title: "Curiosity and Patterns",
    question: "Which dot seems to break the pattern?",
    options: [
      { optionNo: "A", type: "dot", size: "big", color: "grey" }, // Dot 1
      { optionNo: "B", type: "dot", size: "small", color: "grey" }, // Dot 2
      { optionNo: "C", type: "dot", size: "big", color: "orange" }, // Dot 3
      { optionNo: "D", type: "text", text: "None of the dots" }, // Option D: None of the dots
    ],
    answer: "B",
  },
  {
    part: "1",
    title: "Curiosity and Patterns",
    question: "Why is it important to notice patterns in our daily work?",
    options: [
      {
        optionNo: "A",
        type: "text",
        text: "It helps us complete tasks faster.",
      },
      {
        optionNo: "B",
        type: "text",
        text: "Patterns give us a chance to break them and find new solutions.",
      },
      { optionNo: "C", type: "text", text: "Patterns should never be broken." },
      {
        optionNo: "D",
        type: "text",
        text: "Following patterns ensures perfection.",
      },
    ],
    answer: "B",
  },
  {
    part: "2",
    title: "Thinking Outside the Box",
    question:
      "What is the first step to solving a problem when you feel stuck?",
    options: [
      {
        optionNo: "A",
        type: "text",
        text: "Keep trying the same thing over and over.",
      },
      { optionNo: "B", type: "text", text: "Stop and take a break." },
      {
        optionNo: "C",
        type: "text",
        text: "Step outside the box and try a new perspective.",
      },
      { optionNo: "D", type: "text", text: "Ask someone else to do it." },
    ],
    answer: "C",
  },
  {
    part: "2",
    title: "Thinking Outside the Box",
    question: "Curiosity is considered a superpower because:",
    options: [
      {
        optionNo: "A",
        type: "text",
        text: "It helps us ask the right questions and find new paths.",
      },
      { optionNo: "B", type: "text", text: "It makes us invincible." },
      { optionNo: "C", type: "text", text: "It’s the fastest way to success." },
      { optionNo: "D", type: "text", text: "It eliminates all mistakes." },
    ],
    answer: "A",
  },
  {
    part: "3",
    title: "Cross-Functional Collaboration",
    question:
      "What is the main benefit of cross-functional collaboration in a company like Empact Group?",
    options: [
      {
        optionNo: "A",
        type: "text",
        text: "It speeds up the process of completing tasks.",
      },
      {
        optionNo: "B",
        type: "text",
        text: "It creates opportunities for different departments to share knowledge and innovate together.",
      },
      {
        optionNo: "C",
        type: "text",
        text: "It allows people to take breaks more often.",
      },
      { optionNo: "D", type: "text", text: "It keeps everything organized." },
    ],
    answer: "B",
  },
  {
    part: "3",
    title: "Cross-Functional Collaboration",
    question:
      "How did cross-functional collaboration help solve Ms. Zukiswa's problem?",
    options: [
      {
        optionNo: "A",
        type: "text",
        text: "Each team handled their task separately without communication.",
      },
      {
        optionNo: "B",
        type: "text",
        text: "Different teams worked together to anticipate and meet Ms. Zukiswa's needs seamlessly.",
      },
      {
        optionNo: "C",
        type: "text",
        text: "Ms. Zukiswa did all the work herself.",
      },
      {
        optionNo: "D",
        type: "text",
        text: "One department handled everything.",
      },
    ],
    answer: "B",
  },
  {
    part: "4",
    title: "Conclusion",
    question: "How can curiosity benefit your work at Empact Group?",
    options: [
      {
        optionNo: "A",
        type: "text",
        text: "By helping you stay within your comfort zone.",
      },
      {
        optionNo: "B",
        type: "text",
        text: "By encouraging you to ask questions and explore new possibilities.",
      },
      {
        optionNo: "C",
        type: "text",
        text: "By ensuring everything stays the same.",
      },
      { optionNo: "D", type: "text", text: "By avoiding risks." },
    ],
    answer: "B",
  },
  {
    part: "4",
    title: "Conclusion",
    question: "What is the final message of the play regarding innovation?",
    options: [
      {
        optionNo: "A",
        type: "text",
        text: "Innovation is about following the rules.",
      },
      {
        optionNo: "B",
        type: "text",
        text: "Innovation happens when we are curious and collaborate across teams.",
      },
    ],
    answer: "B",
  },
];
