import QuestionLayout from "../questions/QuestionLayout";
import Intro from "../intro/Intro";

const IntroQuestions = ({state, dispatch, messenger}) => {
  if (state.currentQuestionIndex > -1) {
    return <QuestionLayout state={state} dispatch={dispatch} messenger={messenger}/>
  } else {
    return <Intro state={state} dispatch={dispatch} messenger={messenger}/>
  }
}

export default IntroQuestions;