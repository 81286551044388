import React from "react";
import { sendMsg } from "../../../messenger/messenger";

const QuestionAnswer = ({ state, dispatch, messenger }) => {
  function timeTaken() {
    // calculates time taken in seconds since the last question was shown
    return (new Date().getTime() - state.questionStartTime) / 1000;
  }

  function submitAnswer(answerNo) {
    dispatch({ type: "submitLocalAnswer", answer: answerNo });
    sendMsg(messenger, {
      type: "submitAnswer",
      team: state.teamName,
      questionIndex: state.currentQuestionIndex,
      answer: answerNo,
      time: timeTaken(),
    });
  }

  return (
    <>
      <div className="pb-4">
        <p className="fontBold uppercase text-grey-primary pb-2 md:text-sm">
          Question
        </p>
        <p className="fontBold capitalize text-lg md:text-base leading-6">
          {state.currentQuestion.question}
        </p>
      </div>
      <div className="flex flex-col gap-4 pb-1">
        {state.currentQuestion.options.map((option, index) => (
          <button
            key={index}
            type="button"
            disabled={state.questionStartTime === 0}
            className={
              `flex items-center gap-2 border border-grey-primary min-h-70 px-3 rounded-1xl text-base md:text-sm text-left ` +
              (state.currentQuestionAnswer === option.optionNo
                ? "fontBold border-green-500 bg-green-light border-0 shadow-[0px_4px_8px_0px_rgba(161,161,161,0.25)]"
                : "") // conditionl formatting if this is the selected option
            }
            onClick={() => submitAnswer(option.optionNo)}
          >
            <span>{option.optionNo}.</span>
            {option.type === "dot" ? (
              // Render dot if it's a dot-based option

              <div
                className={`rounded-full ${
                  option.size === "big" ? "w-8 h-8" : "w-5 h-5"
                }`}
                style={{
                  backgroundColor: option.color,
                }}
              ></div>
            ) : (
              // Render text option for non-dot based option (e.g., "None of the dots")
              <span>{option.text}</span>
            )}
          </button>
        ))}
      </div>
    </>
  );
};

export default QuestionAnswer;
