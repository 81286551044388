import React from 'react';
import summaryHeader from '../../assets/summaryHeader.png';

const Summary = () => {
  return (
    <div className='h-screen flex flex-col flex-1 px-2 pb-2 lg:px-8'>
      <div className='h-full flex flex-col justify-end sm:mx-auto sm:w-full sm:max-w-sm md:max-w-2xl'>
        <div className='h-auto flex flex-col justify-between bg-grey-main rounded-4xl pt-6 pb-3 px-4 sm:mx-auto sm:w-full sm:max-w-sm md:max-w-2xl'>
            <p className='fontBold text-3xl text-center pb-10'>Well Done!</p>
            <div className='flex flex-col bg-green-light rounded-1xl text-center pb-10 pt-3 mb-10'>
              <img src={summaryHeader} alt='Trophy' className='size-[19rem] mx-auto'/>
              <p className='fontBold text-2xl text-white-main'>You get 700 points</p>
            </div>
            <div className='grid grid-rows-2 grid-flow-col gap-y-4 pb-10'>
              <div className='flex flex-col'>
                <p className='fontBold uppercase text-sm text-grey-primary'>Correct Answers</p>
                <p className='fontBold text-xl'>7 Questions</p>
              </div>
              <div className='flex flex-col'>
                <p className='fontBold uppercase text-sm text-grey-primary'>Unanswered</p>
                <p className='fontBold text-xl'>1</p>
              </div>
              <div className='flex flex-col'>
                <p className='fontBold uppercase text-sm text-grey-primary'>Completion</p>
                <p className='fontBold text-xl'>90%</p>
              </div>
              <div className='flex flex-col'>
                <p className='fontBold uppercase text-sm text-grey-primary'>Incorrect</p>
                <p className='fontBold text-xl'>0</p>
              </div>

            </div>
            
        </div>
      </div>
    </div>
  )
}

export default Summary