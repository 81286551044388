import React from 'react';


const Header = ({ src, width }) => {
  return (
    <div className=''>
    <img
        alt='Login'
        src= {src}
        className={`mx-auto ${width}`}
        />
    </div>
  )
}

export default Header