import React from "react";
import Header from "../../common/header/Header";
import introHeader from "../../assets/introHeader.png";
import avatar from "../../assets/avatar.png";

const Intro = ({ state, dispatch }) => {
  console.log("Intro for team: " + state.teamName);
  return (
    <div className="h-screen flex flex-col flex-1 px-2 pb-2 lg:px-8">
      <div className="h-full flex flex-col justify-end sm:mx-auto sm:w-full sm:max-w-sm md:max-w-2xl">
        <Header src={introHeader} width="max-w-xs md:max-w-xs" />
        <div className="h-auto flex flex-col justify-between bg-grey-main rounded-4xl pt-6 pb-3 px-4 sm:mx-auto sm:w-full sm:max-w-sm md:max-w-2xl">
          <div className="pb-3">
            <p className="fontBold uppercase text-grey-primary pb-1">
              Innovation & Curiosity
            </p>
            <h1 className="fontBold text-2xl">30 Second Game</h1>
          </div>
          <div className="flex w-full bg-green-light rounded-1xl p-2 mb-4 shadow-[0px_4px_8px_0px_rgba(161,161,161,0.25)]">
            <div className="flex w-full items-center gap-2">
              <div className="flex bg-green-main rounded-4xl p-1.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="white"
                  className="size-8"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z"
                  />
                </svg>
              </div>
              <p className="text-sm fontBold">8 questions</p>
            </div>
            <div className="flex w-full items-center gap-2">
              <div className="flex bg-yellow-main rounded-4xl p-1.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="white"
                  className="size-8"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.25 6.087c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.036-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959v0a.64.64 0 0 1-.657.643 48.39 48.39 0 0 1-4.163-.3c.186 1.613.293 3.25.315 4.907a.656.656 0 0 1-.658.663v0c-.355 0-.676-.186-.959-.401a1.647 1.647 0 0 0-1.003-.349c-1.036 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401v0c.31 0 .555.26.532.57a48.039 48.039 0 0 1-.642 5.056c1.518.19 3.058.309 4.616.354a.64.64 0 0 0 .657-.643v0c0-.355-.186-.676-.401-.959a1.647 1.647 0 0 1-.349-1.003c0-1.035 1.008-1.875 2.25-1.875 1.243 0 2.25.84 2.25 1.875 0 .369-.128.713-.349 1.003-.215.283-.4.604-.4.959v0c0 .333.277.599.61.58a48.1 48.1 0 0 0 5.427-.63 48.05 48.05 0 0 0 .582-4.717.532.532 0 0 0-.533-.57v0c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.035 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.37 0 .713.128 1.003.349.283.215.604.401.96.401v0a.656.656 0 0 0 .658-.663 48.422 48.422 0 0 0-.37-5.36c-1.886.342-3.81.574-5.766.689a.578.578 0 0 1-.61-.58v0Z"
                  />
                </svg>
              </div>
              <p className="text-sm fontBold">800 points</p>
            </div>
          </div>
          <div className="pb-12">
            <p className="fontBold uppercase text-grey-primary pb-1">
              Description
            </p>
            <p className="text-base leading-5 pb-7">
              Each round of the game features a quick demonstration, followed by
              a multiple-choice question. Participants must select the correct
              answer within 30 seconds.
            </p>
            <div className="flex gap-2 items-center">
              <img src={avatar} alt="Avatar" className="size-10" />
              <div className="flex flex-col">
                <p className="fontBold text-xs">Ruan Gourley</p>
                <p className="text-xs text-grey-primary">Creator</p>
              </div>
            </div>
          </div>
          {/* <Button
            classNames={
              "fontBold flex w-64 mx-auto justify-center rounded-1xl bg-green-main text-white-main text-xl py-3"
            }
            onClick={() => navigate("/questions")}
          >
            Start
          </Button> */}
        </div>
      </div>
    </div>
  );
};

export default Intro;
