import React from "react";
import QuestionAnswer from "./QuestionAnswer";
import Timer from "../../common/timer/Timer";

const QuestionLayout = ({ state, dispatch, messenger }) => {
  return (
    <div className="max-h-screen h-screen flex flex-col flex-1 px-2 pb-2 lg:px-8">
      <div className="h-full flex flex-col justify-between sm:mx-auto sm:w-full sm:max-w-sm md:max-w-2xl">
        <div class="flex items-center gap-x-1"></div>
        <div className="flex w-10/12 mx-auto h-full items-end py-5 gap-x-1">
          <div
            class="w-2/4 mx-auto h-2.5 flex flex-col justify-center overflow-hidden bg-emerald-700 rounded-s-2xl text-xs text-white text-center whitespace-nowrap transition duration-500"
            role="progressbar"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
          <div
            class="w-2/4 mx-auto h-2.5 flex flex-col justify-center overflow-hidden bg-emerald-700 text-xs text-white text-center whitespace-nowrap transition duration-500"
            role="progressbar"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
          <div
            class="w-2/4 mx-auto h-2.5 flex flex-col justify-center overflow-hidden bg-white-light text-xs text-white text-center whitespace-nowrap transition duration-500"
            role="progressbar"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
          <div
            class="w-2/4 mx-auto h-2.5 flex flex-col justify-center overflow-hidden bg-white-light rounded-e-2xl text-xs text-white text-center whitespace-nowrap transition duration-500"
            role="progressbar"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
          <div
            class="w-2/4 mx-auto h-2.5 flex flex-col justify-center overflow-hidden bg-white-light rounded-e-2xl text-xs text-white text-center whitespace-nowrap transition duration-500"
            role="progressbar"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
          <div
            class="w-2/4 mx-auto h-2.5 flex flex-col justify-center overflow-hidden bg-white-light rounded-e-2xl text-xs text-white text-center whitespace-nowrap transition duration-500"
            role="progressbar"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
          <div
            class="w-2/4 mx-auto h-2.5 flex flex-col justify-center overflow-hidden bg-white-light rounded-e-2xl text-xs text-white text-center whitespace-nowrap transition duration-500"
            role="progressbar"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
          <div
            class="w-2/4 mx-auto h-2.5 flex flex-col justify-center overflow-hidden bg-white-light rounded-e-2xl text-xs text-white text-center whitespace-nowrap transition duration-500"
            role="progressbar"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>

        <div className="h-auto flex flex-col justify-between bg-grey-main rounded-4xl pt-6 pb-3 px-4 sm:mx-auto sm:w-full sm:max-w-sm md:max-w-2xl">
          <div className="pb-5 text-center">
            <p className="fontBold uppercase text-grey-primary md:text-sm">
              Part {state.currentQuestion.part}
            </p>
            <p className="fontBold uppercase text-grey-primary pb-1 md:text-sm">
              {state.currentQuestion.title}
            </p>
          </div>
          <Timer
            key={state.currentQuestionIndex}
            size={120}
            onComplete={() => dispatch({ type: "disableAnswers" })}
          />
          <QuestionAnswer
            state={state}
            dispatch={dispatch}
            messenger={messenger}
          />
          {/* <div className='pb-6'>
                  <p className='fontBold uppercase text-grey-primary pb-3'>Question</p>
                  <p className='fontBold capitalize text-xl leading-6'>What helps inspire creativity in a project team?</p>
                </div>
                <div className='flex flex-col gap-4 pb-5'>
                  <button className='bg-green-light min-h-70 px-1 rounded-1xl text-lg shadow-[0px_4px_8px_0px_rgba(161,161,161,0.25)]'>Giving people a chance to explore new ideas.</button>
                  <button className='border border-grey-primary min-h-70 px-1 rounded-1xl text-lg'>Sticking to routine tasks.</button>
                  <button className='border border-grey-primary min-h-70 px-1 rounded-1xl text-lg'>Discouraging exploration of new skills.</button>
                </div> */}
        </div>
      </div>
    </div>
  );
};

export default QuestionLayout;
