import React from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

const Timer = ({ key, size, onComplete, dashboard }) => {
  const renderTime = ({ remainingTime, color }) => {
    if (remainingTime === 0) {
      return (
        <div className={`text-[#bb0303] ${dashboard ? "text-am" : "text-xs"}`}>
          Time is up!
        </div>
      );
    }

    return (
      <div className="text-center">
        <div
          className={`fontLight text-grey-primary ${
            dashboard ? "text-xs" : "text-xs"
          }`}
        >
          Remaining
        </div>
        <div
          className={`fontLight ${dashboard ? "text-3xl" : "text-3xl"}`}
          style={{ color: color }}
        >
          {remainingTime}
        </div>
        <div
          className={`fontLight text-grey-primary ${
            dashboard ? "text-xs" : "text-xs"
          }`}
        >
          seconds
        </div>
      </div>
    );
  };

  return (
    <div className="flex justify-center pt-1">
      <CountdownCircleTimer
        key={key}
        isPlaying
        duration={30}
        colors={["#83C482", "#E0C016", "#bb0303"]}
        colorsTime={[20, 10, 0]}
        size={size}
        strokeWidth={8}
        trailColor="#C1C1C1"
        fill="current"
        onComplete={onComplete}
      >
        {renderTime}
      </CountdownCircleTimer>
    </div>
  );
};

export default Timer;
